<template>
  <div class="page">
    <el-container class="page-content">
      <el-main width="880px" class="page-content-l" style="padding:0px;">
        <div class="title" @click="goback">
          <div class="backImg" @mouseenter="test" @mouseleave="test">
            <img :src="flag ? one : two" alt="" @click="goback" />
          </div>
          <div>我的收益</div>
        </div>
        <div class="content">
          <div class="money">
            <span>余额</span>
            <p v-if="moneySj.balance != 0">
              {{ moneySj.balance | numFilter }}<span>元</span>
            </p>
            <p v-else>0.00<span>元</span></p>
            <div style="display:flex;" class="wbox">
              <div class="le">
                已提现：<span v-if="moneySj.withdraw_amount != 0">{{
                  moneySj.withdraw_amount | numFilter
                }}</span>
                <span v-else>0.00</span>
              </div>
              <div class="re">
                累计：<span v-if="moneySj.income_amount != 0">{{
                  moneySj.income_amount | numFilter
                }}</span>
                <span v-else>0.00</span>
              </div>
            </div>
          </div>
          <p class="account">
            支付宝账户：{{
              userInfo.alipay_account
                ? userInfo.alipay_account
                : "暂无用户数据"
            }}<span style="margin-left:5px">({{ userInfo.alipay_name }})</span>
            <span style="margin-left:20px" @click="editDialog = true"
              >修改</span
            >
          </p>
          <div class="withdraw-wrap">
            <div class="money-wrap">
              <span>提现金额：</span>
              <el-input-number
                v-model="amount"
                :controls="false"
                @change="handleChange"
                placeholder="请输入金额"
                :step="100"
                :step-strictly="true"
                :min="0"
                :max="100000"
              ></el-input-number>
              <span class="yuan">元</span>
            </div>
          </div>
          <el-row style="margin-top:35px">
            <div class="btn" @click="submitClick" type="primary">提交</div>
          </el-row>
          <!-- 提现记录 -->
          <div class="record">
            <el-table :data="recordList" header-cell-style="background:#f7f7f7">
              <el-table-column label="提现时间" min-width="200">
                <template slot-scope="scope">
                  <div style="display:flex;align-items:center;">
                    <div
                      style="margin-left:10px"
                      class="flex flex-direction justify-around "
                    >
                      <p>
                        <span>{{ scope.row.created_at }}</span>
                      </p>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="提现金额" min-width="180">
                <template slot-scope="scope">
                  <div>
                    <p>{{ scope.row.amount }}</p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="进度" min-width="140">
                <template slot-scope="scope">
                  <div>
                    <p
                      v-if="scope.row.apply_status_str == 0"
                      style="color: #FCC12D;"
                    >
                      {{ scope.row.apply_status_str }}
                    </p>
                    <p
                      v-if="scope.row.apply_status_str == 1"
                      style="color: #2990F9;"
                    >
                      {{ scope.row.apply_status_str }}
                    </p>
                    <p
                      v-if="scope.row.apply_status_str == 2"
                      style="color: #DF2A29;"
                    >
                      {{ scope.row.apply_status_str }}
                    </p>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <el-dialog
          :visible.sync="editDialog"
          width="30%"
          :show-close="false"
          :title="null"
          center
        >
          <div class="anDialog">
            <div class="close" @click="editDialog = false">
              <i class="el-icon-close"></i>
            </div>
            <div class="dtitle">提现绑定</div>
            <div class="account">
              <el-input
                v-model="form.alipay_account"
                @change="handleChange"
                placeholder="请输入支付宝账号"
              ></el-input>
            </div>
            <div class="account">
              <el-input
                v-model="form.alipay_name"
                @change="handleChange"
                placeholder="请输入姓名"
              ></el-input>
            </div>
            <div
              class="btn"
              @click="submitBtn"
              :class="
                form.alipay_account != ''
                  ? 'ogg'
                  : form.alipay_name != ''
                  ? 'ogg'
                  : ''
              "
            >
              确定
            </div>
          </div>
        </el-dialog>
      </el-main>
    </el-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      editDialog: false,
      amount: 0,
      form: {
        alipay_account: "",
        alipay_name: ""
      },
      recordList: [], //提现列表
      flag: true,
      one: require("@/static/starImg/back.png"),
      two: require("@/static/starImg/back1.png"),
      userInfo: [], //支付宝账户信息
      moneySj: {}
    };
  },
  mounted() {
    this.getAccount();
    this.getMoney();
    this.moneyList();
  },
  filters: {
    numFilter(value) {
      // 截取当前数据到小数点后两位
      let realVal = parseFloat(value).toFixed(2);
      return realVal;
    }
  },
  methods: {
    handleChange(val) {
      console.log(val);
    },
    //发起提现
    async submitClick() {
      if (this.amount <= 0) {
        this.$message.error("请输入您要提现的金额");
        return;
      }
      const { info, status } = await this.$api.xtGetMoney({
        amount: this.amount,
        alipay_account: this.userInfo.alipay_account,
        alipay_name: this.userInfo.alipay_name
      });
      if (status == 200) {
        this.$message(info);
        this.moneyList();
      } else {
        this.$message(info);
      }
    },
    //获取账号支付宝信息
    async getAccount(params = {}) {
      const { status, data } = await this.$api.xtAlipayApi(params);
      if (status == 200) this.userInfo = data;
      console.log(data);
    },
    //星探收益框内数据
    async getMoney(params = {}) {
      const { status, data } = await this.$api.xtMoneyApi(params);
      if (status == 200) this.moneySj = data;
      console.log(data);
    },
    //修改提现账户信息
    async submitBtn() {
      const { status, info } = await this.$api.xtChangeAccount({
        ...this.form
      });
      if (status == 200) {
        this.$message(info);
        setTimeout(() => {
          this.getAccount();
          this.editDialog = false;
        }, 1000);
      } else {
        this.$message.error(info);
      }
    },
    //获取提现列表
    async moneyList(params = {}) {
      const { status, data } = await this.$api.xtWithdrawApi(params);
      if (status == 200) this.recordList = data;
    },
    test() {
      this.flag = !this.flag;
    },
    goback() {
      this.$router.push("/star");
    }
  }
};
</script>
<style lang="scss" scoped>
.page {
  background: #f7f7f7;
  min-height: 100vh;
  border: solid 1px #f7f7f7;
}
.ogg {
  background: #df2a29 !important;
}
.page-content {
  width: 1240px;
  margin: 0 auto;
  margin-top: 50px;
}
.title {
  height: 65px;
  line-height: 72px;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  display: flex;
  .backImg {
    margin: 6px 10px 0 0;
  }
}
.content {
  background: #fff;
  border-radius: 4px;
  height: 690px;
  padding-top: 20px;
  padding-left: 20px;
  .money {
    width: 360px;
    height: 146px;
    background: linear-gradient(270deg, #f98c73 0%, #fa4e5a 100%);
    box-shadow: 4px 4px 8px 0px rgba(250, 78, 90, 0.24);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    color: #fff;
    padding-top: 20px;
    padding-left: 20px;
    span {
      height: 14px;
      font-size: 14px;
      color: #ffffff;
      line-height: 21px;
    }
    p {
      font-size: 28px;
      margin-top: 19px;
      span {
        font-size: 14px;
      }
    }
    .wbox {
      margin-top: 20px;
      font-size: 14px;
      color: #ffffff;
      .le {
        margin-right: 96px;
      }
    }
  }
}
.account {
  color: #666666;
  font-size: 14px;
  margin-top: 32px;
}
.money-wrap,
.reality {
  display: flex;
  color: #333333;
  font-size: 14px;
  line-height: 34px;
}
.reality {
  color: #df2a29;
  line-height: 34px;
  margin-left: 20px;
}
.box-card /deep/ .el-input__inner {
  height: 34px;
}
.withdraw-wrap {
  margin-top: 34px;
  display: flex;
}
.yuan {
  margin-left: 10px;
}
.btn {
  width: 90px;
  height: 34px;
  background: #df2a29;
  border-radius: 2px;
  text-align: center;
  line-height: 34px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}
.record {
  margin-top: 20px;
  margin-right: 20px;
}
/deep/ .el-dialog__header {
  padding: 0;
}
/deep/ .el-dialog {
  border-radius: 4px;
}
.anDialog {
  position: relative;
  .close {
    position: absolute;
    right: -14px;
    top: -15px;
    font-size: 19px;
    color: #999999;
  }
  .dtitle {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    margin-top: 10px;
  }
  .btn {
    width: 320px;
    height: 40px;
    background: #e0e0e0;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    margin-top: 30px;
    line-height: 40px;
  }
}
</style>